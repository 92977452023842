import {
    useStyles,
    Caption,
    Avatar,
    Flex,
    Grid,
    pxToRem,
    useTheme,
    Button,
    useAppDisplayModeContext,
} from '@archipro-design/aria';
import React from 'react';
import { Link } from '@remix-run/react';
import { useIsSafari } from '@modules/root/hook/use-is-safari';

import * as S from './CompanyProfile.style';

import { useTracker } from '@archipro-website/tracker';
import CompanyEnquiryForm from './CompanyEnquiryForm';
import { useWebsiteReferralTracking } from '~/modules/tracking/util/websiteTracking';
import type { PrimaryCTAResult } from '~/modules/professional/util/professional-cta-helper';
import { ExternalLinkLine, MapPinFill } from '@archipro-design/icons';
import type { FavouriteItem } from '~/modules/design-board/type';
import LocationDisplay from '~/modules/company-details/LocationDisplay';
import type { EnquiryProfessional } from '~/modules/enquiry/component/enquiry-form/EnquiryForm';
import type { EnquiryItemType } from '../tracking/util/trackEnquireReveal';

import cx from 'classnames';
export const COMPANY_ENQUIRY_FORM_ANCHOR = 'request-price';

export interface CompanyProfileData {
    Professional: EnquiryProfessional;
    primaryCTA?: PrimaryCTAResult;
    siteTreeID: number;
    messageBodyPlaceholder?: string;
    website?: string | null;
    itemType: EnquiryItemType;
}

interface CompanyProfileProps {
    data: CompanyProfileData;
    showViewProfile?: boolean;
    showEnquiryForm?: boolean;
    showCTABottom?: boolean;
    favouriteItem?: FavouriteItem;
    showCustomEnquiryText?: boolean;
    showWhereToBy?: boolean;
    onClickWhereToBuy?: () => void;
}

const CompanyProfile = ({
    data,
    showViewProfile = true,
    showEnquiryForm = true,
    showCTABottom = false,
    favouriteItem,
    showCustomEnquiryText = true,
    showWhereToBy,
    onClickWhereToBuy,
}: CompanyProfileProps) => {
    const {
        Professional,
        website,
        siteTreeID,
        messageBodyPlaceholder,
        primaryCTA,
        itemType,
    } = data;

    const isSafari = useIsSafari();
    const { css } = useStyles({ isSafari });
    const tracker = useTracker();
    const theme = useTheme();
    const { mobile: isMobile } = useAppDisplayModeContext();

    const logo = Professional?.CompanyLogo;
    const logoBackgroundColor = Professional?.LogoBackgroundColor
        ? Professional.LogoBackgroundColor
        : 'white';

    const captionVariables = {
        lineHeight: theme.siteVariables.lineHeightSmall,
    };

    const {
        referralTrackingURL,
        referralTrackingClass,
        referralTrackingParams,
    } = useWebsiteReferralTracking(website || Professional.Website, {
        libraryItemID: siteTreeID,
        professionalTitle: Professional.Title,
    });

    const url = new URL(
        website || Professional.Website || 'https://archipro.co.nz'
    );
    const domain = url.hostname.replace(/^www\./, '');

    const visitWebsiteLabel =
        itemType === 'article' ? 'Visit ' + domain : 'Visit Website';

    return (
        <div className={css(S.CompanyProfileWrapper)}>
            <Grid
                className={css(S.CompanyProfileContainer)}
                columns={`1fr ${pxToRem(133)}`}
                id={COMPANY_ENQUIRY_FORM_ANCHOR}
            >
                <div className={css(S.CompanyProfileInfo)}>
                    <Caption variant="03" variables={captionVariables}>
                        {Professional.Title}
                    </Caption>
                    <LocationDisplay
                        Professional={Professional}
                        variables={captionVariables}
                    />
                    {showViewProfile && (
                        <div>
                            <Caption
                                as={Link}
                                to={Professional.Link}
                                className={css(S.CompanyProfileLink)}
                                onClick={() => {
                                    tracker.log('ProductProfileButtonClick', {
                                        url: new URL(window.location.href),
                                        targetTracker: [
                                            'archiproTracker',
                                            'ga4Tracker',
                                        ],
                                    });
                                }}
                                link={true}
                                variant="03"
                                variables={captionVariables}
                            >
                                View Profile
                            </Caption>
                        </div>
                    )}
                </div>
                <Flex hAlign="end">
                    <Avatar
                        as={Link}
                        to={Professional.Link}
                        image={{
                            src: logo ?? '',
                            alt: `${Professional.Title} company logo`,
                        }}
                        initials={'Logo'}
                        size={106}
                        imageFit={'contain'}
                        styles={{
                            borderWidth: 0,
                        }}
                        variables={{
                            avatarImageBackgroundColor: logoBackgroundColor,
                            avatarBackgroundColor: logoBackgroundColor,
                            borderRadius: pxToRem(4),
                        }}
                    />
                </Flex>
            </Grid>
            {showEnquiryForm && (
                <CompanyEnquiryForm
                    data={{
                        Professional: {
                            ...Professional,
                            Website: Professional.Website ?? '',
                            CompanyLogo: Professional.CompanyLogo ?? '',
                            LogoBackgroundColor:
                                Professional.LogoBackgroundColor ?? 'white',
                            primaryCTA: primaryCTA ?? Professional.primaryCTA,
                        },
                        siteTreeID,
                        messageBodyPlaceholder,
                        showCustomEnquiryText,
                        favouriteItem,
                        itemType,
                    }}
                />
            )}
            {showWhereToBy && !isMobile && (
                <Flex
                    className={css(S.BottomActionContainer)}
                    onClick={onClickWhereToBuy}
                    styles={{
                        marginTop: pxToRem(70),
                        borderBottom: 'unset',
                    }}
                >
                    <Caption className={css(S.BottomActionLabel)}>
                        Where to buy
                    </Caption>
                    <Flex hAlign="end">
                        <MapPinFill
                            variables={{
                                '1xSize': pxToRem(24),
                                color: '#000',
                            }}
                        />
                    </Flex>
                </Flex>
            )}
            {showEnquiryForm && !isMobile && referralTrackingURL && (
                <Flex
                    className={cx(
                        css(S.BottomActionContainer),
                        referralTrackingClass
                    )}
                    as={'a'}
                    href={referralTrackingURL}
                    target={'_blank'}
                    styles={{
                        marginTop: showWhereToBy ? 0 : pxToRem(70),
                    }}
                    {...referralTrackingParams}
                >
                    <Caption className={css(S.BottomActionLabel)}>
                        {visitWebsiteLabel}
                    </Caption>
                    <Flex hAlign="end">
                        <ExternalLinkLine
                            variables={{
                                '1xSize': pxToRem(24),
                                color: '#000',
                            }}
                        />
                    </Flex>
                </Flex>
            )}
            {isMobile && showCTABottom && (
                <div className={css(S.CTABottomContainer)}>
                    {referralTrackingURL && (
                        <Button
                            as={'a'}
                            href={referralTrackingURL}
                            target={'_blank'}
                            className={referralTrackingClass}
                            {...referralTrackingParams}
                        >
                            {visitWebsiteLabel}
                            <ExternalLinkLine
                                variables={{
                                    '1xSize': pxToRem(16),
                                    color: '#222222',
                                }}
                            />
                        </Button>
                    )}
                </div>
            )}
        </div>
    );
};

export default CompanyProfile;
