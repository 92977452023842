import type { CaptionProps } from '@archipro-design/aria';
import { Caption, useStyles } from '@archipro-design/aria';
import { Link } from '@remix-run/react';
import type { LibraryLocationFragmentFragment } from 'generated/graphql';
import * as S from './LocationDisplay.style';
import classNames from 'classnames';

type Location = Pick<LibraryLocationFragmentFragment, 'Suburb' | 'Region'>;

interface LocationDisplayProps extends CaptionProps {
    Professional: {
        LibraryLocations: Location[];
        Link: string;
    };
    onClick?: React.MouseEventHandler<HTMLAnchorElement>;
}

const joinSingleLibraryLocationString = (libraryLocation: Location) => {
    const { Suburb, Region } = libraryLocation;
    return [Suburb, Region]
        .filter((x) => (x ?? '').trim().length > 0)
        .join(', ');
};

const LocationDisplay = ({ Professional, ...props }: LocationDisplayProps) => {
    const { css } = useStyles();
    if (!Professional.LibraryLocations?.length) {
        return null;
    }

    if (
        Professional.LibraryLocations.length === 1 &&
        Professional.LibraryLocations[0]
    ) {
        return (
            <Caption
                as={Link}
                to={`${Professional.Link}/contact`}
                variant="03"
                className={classNames(
                    css(S.LocationCaptionStyle),
                    props.className
                )}
                {...props}
            >
                {joinSingleLibraryLocationString(
                    Professional.LibraryLocations[0]
                )}
            </Caption>
        );
    }

    return (
        <Caption
            as={Link}
            to={`${Professional.Link}/contact`}
            variant="03"
            className={classNames(css(S.LocationCaptionStyle), props.className)}
            {...props}
        >
            View locations ({Professional.LibraryLocations.length})
        </Caption>
    );
};

export default LocationDisplay;
