import type { StyleRule } from '@archipro-design/aria';
import {
    pxToRem,
    captionClassName,
    labelClassName,
    boxClassName,
    buttonClassName,
} from '@archipro-design/aria';
import { svgIconClassName } from '@fluentui/react-icons-northstar';

export const CompanyProfileWrapper: StyleRule = ({ theme }) => ({
    paddingTop: pxToRem(18),
    [theme.screen.tablet]: {
        marginTop: pxToRem(21),
    },
});

export const CompanyProfileContainer: StyleRule = () => ({
    alignItems: 'center',
    gap: pxToRem(8),
    marginBottom: pxToRem(32),
    width: '100%',
});

export const CompanyProfileInfo: StyleRule = () => ({
    paddingTop: pxToRem(15),
});

export const CompanyProfileLink: StyleRule = () => ({
    [`&.${captionClassName}`]: {
        textDecoration: 'underline',
    },
    display: 'inline',
});

export const AnchorContainer: StyleRule = () => {
    const offset = 130;
    return {
        marginTop: pxToRem(offset * -1),
        paddingTop: pxToRem(offset),
    };
};

export const EnquiryTitle: StyleRule = () => ({
    textTransform: 'uppercase',
    marginBottom: pxToRem(15),
});

export const CTABottomContainer: StyleRule = ({ theme }) => ({
    marginTop: pxToRem(12.5),
    [`& .${buttonClassName}`]: {
        width: '100%',
        padding: pxToRem(24),
        height: pxToRem(48),
        border: '1px solid #222222',
        background: 'transparent',
        marginBottom: pxToRem(10),

        ':active': {
            background: 'transparent',
            border: '1px solid #222222',
        },

        [`& > .${labelClassName}`]: {
            ...theme.siteVariables.textStyles.Heading.Roman55,
            fontSize: pxToRem(16),
            color: '#222222',
            lineHeight: pxToRem(24),
            textTransform: 'uppercase',
        },
        [`& .${svgIconClassName}`]: {
            marginLeft: pxToRem(8),
            paddingTop: pxToRem(2),
            verticalAlign: 'unset',
        },
    },
});

export const DesignBoardGroup: StyleRule = ({ theme }) => ({
    marginRight: pxToRem(4),
    marginLeft: pxToRem(-7),

    ['& .' + boxClassName]: {
        marginRight: '0',
        '&:not(:last-child)': {
            marginRight: '0',
        },
        ['& .' + svgIconClassName]: {
            width: pxToRem(40),
            height: pxToRem(40),
        },
    },

    [theme.screen.min.tablet]: {
        display: 'block',
    },
});

export const HeartButton: StyleRule = ({ theme }) => ({
    display: 'none',
    borderRadius: '50%',
    [theme.screen.tablet]: {
        display: 'block',
    },
});

export const BottomActionContainer: StyleRule = ({ theme }) => ({
    borderTop: `1px solid ${theme.siteVariables.colors.primitive.black}`,
    borderBottom: `1px solid ${theme.siteVariables.colors.primitive.black}`,
    height: pxToRem(80),
    alignItems: 'center',
    justifyContent: 'space-between',
    paddingRight: pxToRem(8),
    cursor: 'pointer',

    [`&:hover > div`]: {
        color: theme.siteVariables.colors.charcoal['100'],

        [`& .${svgIconClassName} svg`]: {
            fill: theme.siteVariables.colors.charcoal['100'],
        },
    },
});

export const BottomActionLabel: StyleRule = ({ theme }) => ({
    [`&.${captionClassName}`]: {
        ...theme.siteVariables.textStyles.Heading.Medium65,
        fontSize: pxToRem(16),
        color: theme.siteVariables.colors.primitive.black,
        lineHeight: pxToRem(18),
        textTransform: 'uppercase',
    },
});
