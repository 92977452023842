import { usePermissions } from '@modules/user';
import { PermissionEnum } from 'generated/graphql';

/**
 * @deprecated use getPermissions in the loader instead.
 * @param professionalId
 */
export const useCanEdit = (professionalId?: number) => {
    return usePermissions(
        [PermissionEnum.ApProductEdit, PermissionEnum.BmProductsEdit],
        professionalId
    );
};
